<template>
  <a-card :bordered="false" class="card-area">

    <div class="data-area" hidden>
      <statistics-data-item
        title="设备总数"
        :value="statistics.total" bgColor="#F6F9FC" color="#0084ff" :icon="$image('ic_device_fault_total@3x.png')" />
      <statistics-data-item
        title="在线设备"
        :value="statistics.totalOnline" bgColor="#F3FFF8" color="#0BD169" :icon="$image('ic_device_fault_processed@3x.png')" />
      <statistics-data-item
        title="今日新增设备"
        :value="statistics.totalToday" bgColor="#FFFAF5" color="#FFB764" :icon="$image('ic_device_fault_today@3x.png')" />
      <statistics-data-item
        title="近7天新增设备"
        :value="statistics.totalWeek" bgColor="#FFF3F3" color="#FF7B7B" :icon="$image('ic_device_fault_7@3x.png')" />
    </div>

    <common-table
      ref="table"
      path="device"
      search-placeholder="搜索设备名称/MAC/固件版本"
      :columns="columns"
      :queryParams="queryParams"
      @reset="reset">

      <template slot="search">
        <div style="display: flex; justify-content: space-between;">
          <div>
            <a-select v-model="queryParams.productKey" @change="productChange" class="table-select" style="width: 10rem">
              <a-select-option value="">全部产品</a-select-option>
              <a-select-option v-for="product in productList" :value="product.productKey">{{product.name}}
              </a-select-option>
            </a-select>
          </div>

          <div>
            地址：
            <a-input style="width:15rem;" v-model="queryParams.address"/>
          </div>
        </div>
      </template>

      <template slot="icon" slot-scope="{record}">
        <img class="product-icon" :src="record.devicePicture"/>
      </template>

      <template slot="firmware" slot-scope="{record}">
        <a-tag color="blue">{{record.firmwareVersion}}</a-tag>
      </template>

      <template slot="mcuVersion" slot-scope="{record}">
        <a-tag color="blue">{{record.mcuVersion}}</a-tag>
      </template>

      <template slot="status" slot-scope="{record}">
        <a-tag v-if="record.status==1" color="green">在线</a-tag>
        <a-tag v-else color="cyan">离线</a-tag>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-upgrade @click="upgrade(record)"></action-upgrade>
      </template>

    </common-table>

    <firmware-select ref="firmwareSelect"></firmware-select>

  </a-card>

</template>
<script>

  import FirmwareSelect from './FirmwareSelect'

  export default {
    components:{FirmwareSelect},
    data () {
      return {
        queryParams: {
          productKey: '',
          address: ''
        },
        productList: [],
        statistics: {
          total: 0,
          totalOnline: 0,
          totalToday: 0,
          totalWeek: 0
        },
      }
    },
    created () {

      this.productKey = this.$route.params.productkey || ''
      this.getProductList()

      this.$get('device/overview', {}).then((r) => {
        this.statistics = r.data
      })
    },
    computed: {
      columns () {
        let {filteredInfo} = this
        return [
          // {
          //   title: '',
          //   dataIndex: 'productIcon',
          //   scopedSlots: {customRender: 'icon'}
          // },
          {
            title: '产品',
            dataIndex: 'productName',
          },
          // {
          //   title: '设备ID',
          //   dataIndex: 'iotId'
          // },
          {
            title: '设备MAC',
            dataIndex: 'name'
          },
          {
            title: '设备名称',
            dataIndex: 'nickname'
          },
          {
            title: 'WIFI固件',
            dataIndex: 'firmwareVersion',
            scopedSlots: {customRender: 'firmware'},
          },
          {
            title: 'MCU固件',
            dataIndex: 'mcuVersion',
            scopedSlots: {customRender: 'mcuVersion'},
          },
          {
            title: '在线状态',
            dataIndex: 'status',
            scopedSlots: {customRender: 'status'},
            filters: [
              {text: '全部', value: '0'},
              {text: '在线', value: '1'},
              {text: '离线', value: '3'}
            ],
            filterMultiple: false,
          },
          {
            title: '地址',
            dataIndex: 'address'
          },
          {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: {customRender: 'operation'},
          }
        ]
      }
    },
    methods: {

      productChange (productKey) {
        this.queryParams.productKey = productKey
        this.getList()
      },

      reset () {
        this.queryParams = {
          productKey: '',
          address: ''
        }
      },

      view (record) {
        this.$router.push(`/device/info/${record.iotId}`)
      },

      getList () {
        this.$refs.table.getData()
      },
      getProductList () {
        this.$get('product').then((r) => {
          let data = r.data
          this.productList = data
        })
      },

      upgrade(record){
        if(record.status!=1){
          this.$message.info('在线设备才能升级固件');
          return
        }
        this.$refs.firmwareSelect.show(record)
      }

    }
  }
</script>
<style lang="less">
  .data-area {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

</style>
